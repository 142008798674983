<template>
  <div class="slide-wrap" v-loading="loadCat">
    <div class="slide">
      <el-menu
        :default-active="value + ''"
        text-color="#333"
        active-text-color="#3F6AF6"
      >
        <!-- <el-menu-item @click="changeCurItem({ id: '' })" index>
          <div class="title-name thidden" slot="title">全部</div>
        </el-menu-item> -->
        <el-menu-item
          v-for="item in menus.data"
          :key="item.id"
          @click="changeCurItem(item)"
          :index="item.id + ''"
        >
          <div class="title-name thidden" slot="title">{{ item.title }}</div>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="c-bottom" v-if="curPage < Math.ceil(menus.total / 20)">
      <el-link @click="loadMore">加載更多</el-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      default: "getGoodsRule",
    },
  },
  mounted() {
    this.getCats()
  },
  data() {
    return {
      loadCat: false,
      menus: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      value: "",
      curPage: 1,
      curIndex: [],
    }
  },
  methods: {
    async getCats({ page = 1, more = false } = { page: 1, more: false }) {
      this.loadCat = true
      let res = await this.api.ads[this.url](page)
      if (res && res.code == 0) {
        if (more) {
          if (
            res.data &&
            Object.prototype.toString.call(res.data.data) == "[object Array]"
          ) {
            for (let i = 0; i < res.data.data; i++) {
              this.menus.data.push(res.data.data[i])
            }
          }
        } else {
          this.menus = res.data
          if (
            res.data &&
            Object.prototype.toString.call(res.data.data) == "[object Array]"
          ) {
            this.changeCurItem(res.data.data[0])
            this.value = res.data.data[0].id
          }
        }
        this.curPage = res.data.current_page
      }
      this.loadCat = false
    },
    loadMore() {
      this.getCats({ page: this.curPage + 1, more: true })
    },
    changeCurItem(item) {
      this.$emit("changeCategory", item, this.type)
    },
  },
}
</script>
<style scoped>
.slide-wrap {
  position: relative;
  width: 159px;
  height: 400px;
  max-height: 400px;
  border: 1px solid #eee;
  box-sizing: border-box;
  background: #fff;
  flex: 0 0 159px;
}
.slide {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 40px;
  right: 0;
  overflow: auto;
  padding-bottom: 50px;
}
.el-menu {
  width: 100%;
  border: none !important;
}
.el-submenu .el-menu-item {
  min-width: 0;
}
>>> .el-submenu .el-submenu__icon-arrow {
  left: 6px;
  right: inherit;
  top: 53%;
}
.title-name {
  padding-left: 10px;
  max-width: 100px;
}
.sub-title {
  padding-left: 0;
}
.el-icon-more {
  position: absolute;
  right: 5px;
  top: 15px;
}
.el-icon-more:hover,
.hover.el-icon-more {
  color: #409eff;
}
.el-icon-more:focus {
  border: none;
  outline: none;
}
.c-menu-pop-list a {
  line-height: 30px;
  display: block;
  color: #666;
}
.c-menu-pop-list a:hover {
  color: #409eff;
}
.c-bottom {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 38px;
  border-top: 1px solid #eee;
  color: #409eff;
  background: #fff;
}
.c-bottom:hover {
  opacity: 0.8;
}
</style>
